/* eslint-disable react-hooks/rules-of-hooks */
/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  ListItem,
  OrderedList,
  Table,
  TableContainer,
  Tbody,
  Td,
  Thead,
  Tr,
  UnorderedList,
} from "@chakra-ui/react";
import { useContext } from "react";

import { Blockquote } from "./Blockquote";
import { Image } from "./Image";
import { Link } from "./Link";
import { H2, H3, Paragraph } from "./Text";
import { theme } from "./theme";
import { VariantContext } from "./Variant";

const useVariant = () => useContext(VariantContext);

export const components = {
  img: Image,
  a: Link,
  h2: (props: any) => <H2 mt={6} {...props} />,
  h3: (props: any) => (
    <H3
      mt={6}
      sx={{ "h2 + &": { mt: `calc(${theme.space.parSpace} * 0.8)` } }}
      {...props}
    />
  ),
  p: Paragraph,
  blockquote: Blockquote,
  pre: (props: any) => (
    <pre
      {...props}
      style={{
        marginTop: theme.space.parSpace,
        marginBottom: `calc(${theme.space.parSpace} * 1.5)`,
        overflowX: "auto",
      }}
    />
  ),
  ul: (props: any) => <UnorderedList ml={3} pl={3} {...props} />,
  li: (props: any) => <ListItem sx={{ "& > p": { mb: 0 } }} {...props} />,
  ol: (props: any) => <OrderedList ml={3} pl={3} {...props} />,
  table: (props: any) => {
    const { tableWrap } = useVariant();

    return (
      <TableContainer whiteSpace={tableWrap ? "normal" : "nowrap"}>
        <Table
          colorScheme="gray"
          margin="auto"
          width="unset"
          size="sm"
          {...props}
        />
      </TableContainer>
    );
  },
  tbody: Tbody,
  td: (props: any) => (
    <Td
      fontSize="inherit"
      lineHeight="normal"
      textAlign={props?.align}
      {...props}
    />
  ),
  th: (props: any) => (
    <Td fontSize="inherit" textAlign={props?.align} {...props} />
  ),
  thead: Thead,
  tr: Tr,
};

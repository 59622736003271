import { Box, Grid, Link } from "@chakra-ui/react";
import { Fragment } from "react";

import { PostMeta } from "../../renderer/types";
import { Date } from "../Date";
import { H1, Text } from "../Text";
import { useColors } from "../useColors";

export const Header = ({ meta }: { meta?: PostMeta }) => {
  const { title, subtitle, date, tags = [] } = meta ?? {};
  const { subtitleColor } = useColors();

  return (
    <Box mb={10} as="header">
      <Grid
        templateColumns="max-content 1fr"
        alignItems="center"
        justifyItems="stretch"
        gap={1}
      >
        <Text textStyle="sans" fontSize="sm" color={subtitleColor}>
          <Text as="i">by </Text>
          <Link href="https://cjquines.com/">CJ Quines</Link>
          <Text as="span"> • </Text>
          <Text as="i">on </Text>
          <Date date={date} />
          {tags.length > 0 ? (
            <>
              <Text as="span"> • </Text>
              <Text as="i">in </Text>
            </>
          ) : null}
          {tags.map((tag, i) => (
            <Fragment key={tag}>
              <Link href="#tagpage">{tag}</Link>
              {i < tags.length - 1 ? ", " : ""}
            </Fragment>
          ))}
        </Text>
        <Box h={0.5} bg={subtitleColor} borderRadius="full" />
      </Grid>
      <H1 mt={5}>{title}</H1>
      <Text fontSize="lg" fontStyle="italic" mt={1} wrap="balance">
        {subtitle}
      </Text>
    </Box>
  );
};

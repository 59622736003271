import { ChakraProvider, ColorModeScript } from "@chakra-ui/react";
import { MDXProvider } from "@mdx-js/react";
import React from "react";

import { PageContext } from "../renderer/types";
import { PageContextProvider } from "../renderer/usePageContext";
import { ColorModeButton } from "./ColorModeButton";
import { components } from "./components";
import { theme } from "./theme";

import "./PageShell.css";

export const PageShell = ({
  children,
  pageContext,
}: {
  children: React.ReactNode;
  pageContext: PageContext;
}) => {
  return (
    <React.StrictMode>
      <PageContextProvider pageContext={pageContext}>
        <ChakraProvider theme={theme}>
          <ColorModeScript initialColorMode="system" />
          {/* @ts-expect-error: don't worry about it */}
          <MDXProvider components={components}>
            <ColorModeButton />
            {children}
          </MDXProvider>
        </ChakraProvider>
      </PageContextProvider>
    </React.StrictMode>
  );
};

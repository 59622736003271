import React, { PropsWithChildren, createContext, useContext } from "react";

import { PageContext } from "./types";

const Context = createContext<PageContext>(undefined as unknown as PageContext);

export const PageContextProvider = ({
  pageContext,
  children,
}: PropsWithChildren<{ pageContext: PageContext }>) => (
  <Context.Provider value={pageContext}>{children}</Context.Provider>
);

// eslint-disable-next-line react-refresh/only-export-components
export const usePageContext = () => useContext(Context);

import { Flex } from "@chakra-ui/react";

import { SUBSCRIBE_URL, TITLE } from "../../renderer/constants";
import { Link } from "../Link";
import { Paragraph } from "../Text";

export const Footer = () => {
  return (
    <Flex
      as="footer"
      mx="auto"
      mt={10}
      mb={40}
      w="full"
      maxW="40em"
      py={10}
      direction="column"
      align="center"
      gap={1}
      opacity={0.5}
      transition="0.3s opacity"
      _hover={{ opacity: 1 }}
      _focusWithin={{ opacity: 1 }}
    >
      <Paragraph my={0}>
        I&rsquo;m <Link href="https://cjquines.com/">CJ Quines,</Link> and
        you&rsquo;re reading <Link href="/">{TITLE}.</Link>
      </Paragraph>
      <Paragraph my={0}>
        Subscribe <Link href="/atom.xml">via RSS.</Link> Subscribe{" "}
        <Link href={SUBSCRIBE_URL}>via email.</Link>
      </Paragraph>
    </Flex>
  );
};

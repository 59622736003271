import {
  Link as ChakraLink,
  HTMLChakraProps,
  LinkProps,
} from "@chakra-ui/react";
import React, { forwardRef } from "react";

import { useColors } from "./useColors";

const LinkInternal = (
  props: HTMLChakraProps<"a"> &
    LinkProps & {
      icon?: string;
    },
  ref: React.ForwardedRef<HTMLAnchorElement> | null,
) => {
  const { href, children, icon: icon_, ...otherProps } = props;

  const isExternal = !!href?.startsWith("http");
  const isAnchor = !!href?.startsWith("#");

  const icon = icon_ ?? (isExternal ? "°" : isAnchor ? "#" : "†");
  const isIconSmall = !isExternal;

  const { linkColor, linkBackground } = useColors();

  return (
    <ChakraLink
      color={linkColor}
      href={href}
      isExternal={isExternal}
      ref={ref}
      sx={{
        "&:hover": {
          textDecoration: "none",
          bg: linkBackground,
        },
        "&:focus": {
          bg: linkBackground,
        },
        "&::after": icon && {
          content: `"${icon}"`,
          ...(isIconSmall && {
            fontSize: "0.75em",
            verticalAlign: "super",
          }),
        },
      }}
      {...otherProps}
    >
      {children}
    </ChakraLink>
  );
};

export const Link = forwardRef(LinkInternal);

import { Root, createRoot, hydrateRoot } from "react-dom/client";

import { consumeContext } from "./consumeContext";
import { PageContextClient } from "./types";

let root: Root;

const render = async (pageContext: PageContextClient) => {
  const { isHydration, metas } = pageContext;
  const { getPage, getPageTitle } = consumeContext(pageContext);

  document.title = getPageTitle(metas.meta);

  const container = document.getElementById("react-root")!;
  if (isHydration) {
    root = hydrateRoot(container, getPage(metas));
  } else {
    if (!root) {
      root = createRoot(container);
    }
    root.render(getPage(metas));
  }
};

export default render;

import { Box, Link } from "@chakra-ui/react";

import { PostMeta } from "../../renderer/types";
import { Text } from "../Text";
import { useColors } from "../useColors";

export const NavPreview = ({
  meta,
  prev,
}: {
  meta?: PostMeta;
  prev: boolean;
}) => {
  const { url, title, subtitle } = meta ?? {};
  const { linkBackground, subtitleColor } = useColors();

  return (
    <Box textAlign={prev ? "left" : "right"}>
      {meta ? (
        <Link
          href={url}
          display="flex"
          flexDirection="column"
          justifyContent="center"
          h="full"
          p={4}
          _hover={{
            textDecoration: "none",
            bg: linkBackground,
          }}
          _focus={{ bg: linkBackground }}
        >
          <Text textStyle="sans" fontSize="xs" color={subtitleColor}>
            {prev ? "Older" : "Newer"}
          </Text>
          <Text fontWeight="bold" wrap="balance">
            {title}
          </Text>
          <Text fontStyle="italic" wrap="balance">
            {subtitle}
          </Text>
        </Link>
      ) : null}
    </Box>
  );
};

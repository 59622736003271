import { Image as ChakraImage } from "@chakra-ui/react";
import {
  DetailedHTMLProps,
  ImgHTMLAttributes,
  SourceHTMLAttributes,
} from "react";

type LowercaseKeys<T> = { [P in keyof T as Lowercase<string & P>]: T[P] };

export const Image = (
  props: Omit<
    DetailedHTMLProps<ImgHTMLAttributes<HTMLImageElement>, HTMLImageElement>,
    "src"
  > & {
    src: string | LowercaseKeys<SourceHTMLAttributes<HTMLSourceElement>>[];
  },
) => {
  const { src } = props;
  if (typeof src === "string") {
    // @ts-expect-error: the props should match in this case
    return <img {...props} />;
  }

  const fixAttrs = (attrs: (typeof src)[number]) => {
    const { srcset, ...rest } = attrs;
    return { srcSet: srcset, ...rest };
  };

  return (
    <picture>
      {src.map((attrs, i) => (
        <source key={i} {...fixAttrs(attrs)} />
      ))}
      <ChakraImage {...fixAttrs(src.at(-1)!)} my="parSpace" />
    </picture>
  );
};

import { Box, Grid } from "@chakra-ui/react";
import { PropsWithChildren } from "react";

import { PostMeta } from "../../renderer/types";
import { Comments } from "./Comments";
import { Footer } from "./Footer";
import { Header } from "./Header";
import { NavPreview } from "./NavPreview";
import { SiteHeader } from "./SiteHeader";

export const Post = ({
  prevMeta,
  meta,
  nextMeta,
  children,
}: PropsWithChildren<{
  prevMeta?: PostMeta;
  meta?: PostMeta;
  nextMeta?: PostMeta;
}>) => {
  return (
    <>
      <SiteHeader />
      <Box as="article" mx="auto" w="full" maxW="40em" p={4}>
        <Header meta={meta} />
        <Box my={10} as="main">
          {children}
        </Box>
      </Box>
      <Grid
        as="nav"
        templateColumns="repeat(auto-fit, minmax(20em, 1fr))"
        alignItems="stretch"
        mx="auto"
        mb={10}
        w="full"
        maxW="50em"
      >
        <NavPreview meta={prevMeta} prev={true} />
        <NavPreview meta={nextMeta} prev={false} />
      </Grid>
      <Comments meta={meta} />
      <Footer />
    </>
  );
};

import { PropsWithChildren, createContext } from "react";

const defaultVariant = {
  tableWrap: false,
};

type VariantProps = typeof defaultVariant;

export const VariantContext = createContext<VariantProps>(defaultVariant);

/**
 * Used for styling a Markdown block element with a class, e.g.
 *
 * <Variant tableWrap>
 *
 * |   | A | B | C |
 * |---|---|---|---|
 * | 1 |   |   |   |
 * | 2 |   |   |   |
 * | 3 |   |   |   |
 *
 * </Variant>
 */
export const Variant = ({
  children,
  ...variants
}: PropsWithChildren<Partial<VariantProps>>) => (
  <VariantContext.Provider value={{ ...defaultVariant, ...variants }}>
    {children}
  </VariantContext.Provider>
);

import {
  Text as ChakraText,
  HTMLChakraProps,
  TextProps,
} from "@chakra-ui/react";
import React, { forwardRef } from "react";

const TextInternal = (
  props: HTMLChakraProps<"p"> &
    TextProps & {
      wrap?: "wrap" | "nowrap" | "balance" | "pretty" | "stable";
    },
  ref: React.ForwardedRef<HTMLAnchorElement> | null,
) => {
  const { children, wrap, ...otherProps } = props;

  return (
    <ChakraText
      ref={ref}
      sx={{
        ...(wrap && {
          textWrap: wrap,
        }),
      }}
      {...otherProps}
    >
      {children}
    </ChakraText>
  );
};

export const Text = forwardRef(TextInternal);

const ParagraphInternal = (
  props: React.ComponentPropsWithRef<typeof TextInternal>,
  ref: React.ForwardedRef<HTMLAnchorElement> | null,
) => <Text ref={ref} my="parSpace" wrap="pretty" {...props} />;

export const Paragraph = forwardRef(ParagraphInternal);

const HeadingInternal = (
  props: React.ComponentPropsWithRef<typeof TextInternal> & {
    level: 1 | 2 | 3;
  },
  ref: React.ForwardedRef<HTMLAnchorElement> | null,
) => {
  const { level, ...otherProps } = props;
  const hLevel = `h${level}` as const;

  return (
    <Text
      ref={ref}
      as={hLevel}
      textStyle={hLevel}
      wrap="balance"
      {...otherProps}
    />
  );
};

export const Heading = forwardRef(HeadingInternal);

const H1Internal = (
  props: React.ComponentPropsWithRef<typeof TextInternal>,
  ref: React.ForwardedRef<HTMLAnchorElement> | null,
) => <Heading ref={ref} level={1} {...props} />;

export const H1 = forwardRef(H1Internal);

const H2Internal = (
  props: React.ComponentPropsWithRef<typeof TextInternal>,
  ref: React.ForwardedRef<HTMLAnchorElement> | null,
) => <Heading ref={ref} level={2} {...props} />;

export const H2 = forwardRef(H2Internal);

const H3Internal = (
  props: React.ComponentPropsWithRef<typeof TextInternal>,
  ref: React.ForwardedRef<HTMLAnchorElement> | null,
) => <Heading ref={ref} level={3} {...props} />;

export const H3 = forwardRef(H3Internal);
